import useIntersectionObserver from "../../../Functions/IntersectionObserver";
import { FaEnvelope, FaTwitter } from "react-icons/fa6";
import sherozImage from "./Images/sheroz_akram.jpeg";
import hafizImage from "./Images/hafiz_ali.jpg";

let TeamList = [
  {
    name: "Sheroz Akram",
    position: "DinoSoft Technologies",
    image: sherozImage,
    desc: "Meet Sheroz Akram, CEO of Dino Soft Tech, leading with innovation and guiding the team to deliver exceptional solutions while driving the company's vision.",
    contact: [
      {
        name: "Twitter",
        icon: <FaTwitter />,
        link: "https://x.com/SherozAkramDev",
      },
      {
        name: "Email",
        icon: <FaEnvelope />,
        link: "mailto:dinosoft.tech@proton.me",
      },
    ],
  },
  {
    name: "Hafiz Ali Asghar",
    position: "DinoSoft Technologies",
    image: hafizImage,
    desc: "Hafiz Ali Asghar, who excels at crafting robust and innovative solutions to bring our projects to life, driving technical excellence and collaboration.",
    contact: [],
  },
];

function MeetTeam() {
  // Handle On Scroll Animations
  const [titleRef, isTitleVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [descRef, isDescVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <>
      <section id="team" class="bg-white dark:bg-gray-900">
        <div class="max-w-screen-xl mx-auto mt-10 mb-10 px-5 bg-primaryBackgoundColor ">
          <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
            <h2
              ref={titleRef}
              class={`mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white ${
                isTitleVisible ? "animate-fade-left" : "opacity-0"
              }`}
            >
              Our Team
            </h2>
            <p
              ref={descRef}
              class={`font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400 ${
                isDescVisible ? "animate-fade-up" : "opacity-0"
              }`}
            >
              A passionate group of innovators dedicated to delivering
              exceptional solutions and driving success through collaboration
              and expertise.
            </p>
          </div>
          <div class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
            {TeamList.map((value, index) => {
              return (
                <TeamMember
                  name={value.name}
                  position={value.position}
                  image={value.image}
                  desc={value.desc}
                  contact={value.contact}
                />
              );
            })}
          </div>
          <div className="bg-primaryFontColor w-full mt-10 h-[1px]"></div>
        </div>
      </section>
    </>
  );
}

const TeamMember = ({ name, position, image, desc, contact }) => {
  const [teamRef, isTeamVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <>
      <div
        ref={teamRef}
        class={`items-center bg-secondaryBackgroundColor rounded-lg shadow sm:flex hover:border-2 hover:border-secondaryFontColor animated-border ${
          isTeamVisible ? "animate-fade" : "opacity-0"
        }`}
      >
        <span className="h-full w-full">
          <img
            class="w-full sm:h-full object-cover rounded-lg sm:rounded-none sm:rounded-l-lg"
            src={image}
            alt="Bonnie Avatar"
          />
        </span>
        <div class="p-5">
          <h3 class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            <span>{name}</span>
          </h3>
          <span class="text-gray-500 dark:text-gray-400">{position}</span>
          <p class="mt-3 mb-4 font-light text-secondaryFontColor hover:text-primaryFontColor">
            {desc}
          </p>
          <ul class="flex space-x-4 sm:mt-0">
            {contact.map((value, index) => {
              return (
                <li>
                  <a
                    href={value.link}
                    class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                  >
                    {value.icon}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MeetTeam;
