
import { Link } from "react-router-dom";
import logo  from "../Images/logo.png"
import estimateIcon from "./estimateIcon.png"
import menuIcon from "./menuIcon.png"
import { useState } from "react";

function NavigationBar() {

    // Mobile Menu Visibility Status
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    // Function to Toggle Menu Visibility
    const toggleMenu = (event) =>{
        setIsMenuVisible(!isMenuVisible);
    }

    // Handle Click of Estimate Button
    let estimateButtonHandler = (event) => {
        window.open("/Estimate", "_self");
    }

    // Handle Click on Logo Button
    let logoButtonHandler = (event) => {
        window.open("/")
    }


    return (
        <>
            <nav className="max-w-screen-xl mx-auto">
                <div className="flex justify-between p-2 px-3 sm:px-8 bg-primaryBackgoundColor" onClick={logoButtonHandler}>
                    <div className="flex flex-col items-center">
                        <img
                            className="w-32"
                            src={logo}
                            alt="DinoSoft Logo"
                            loading="eager"
                            importance="high"
                            fetchpriority="high"
                        />
                        <h1 className="text-lg sm:text-2xl font-bold text-primaryFontColor">DinoSoft Tech</h1>
                    </div>
                    <div className="flex items-center">
                        <div className="mr-2 font-medium leading-tight sm:mr-4 sm:p-3 text-end">
                            <span className="text-2xl sm:text-3xl text-primaryFontColor">Call Us Today</span>
                            <br />
                            <span className="text-2xl text-primaryFontColor">+92-313-7385206</span>
                        </div>
                        <div className="hidden p-2 px-4 font-medium leading-tight text-center text-primaryFontColor uppercase border border-white rounded-md sm:block">
                            <div className="flex items-center" onClick={estimateButtonHandler}>
                                <img className="w-9" src={estimateIcon} alt="Pen Logo" />
                                <div className="text-3xl">free</div>
                            </div>
                            <div>estimate</div>
                        </div>
                    </div>
                </div>
                <div className="items-center justify-between text-xl text-primaryFontColor bg-secondaryBackgroundColor md:flex">
                    <div className="cursor-pointer md:hidden" onClick={toggleMenu} id="menu-button">
                        <div className="flex items-center justify-center p-2 text-xl font-medium">
                            <img className="w-5 h-5 mx-2" src={menuIcon} alt="menu" />
                            <div>Menu</div>
                        </div>
                    </div>
                    <div className={`md:block ${isMenuVisible ? 'block' : 'hidden'}`} id="menu">
                        <div className="items-center justify-between md:flex">
                            <ul className="items-center p-3 md:divide-white md:divide-x md:p-0 md:flex">
                                <li className="p-4 my-2 bg-secondaryBackgroundColor md:bg-transparent hover:bg-primaryHoverColor md:m-0">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="p-4 mb-2 bg-secondaryBackgroundColort hover:bg-primaryHoverColor  md:m-0">
                                    <Link to="/About">About Us</Link>
                                </li>
                                <li className="p-4 mb-2 bg-secondaryBackgroundColor hover:bg-primaryHoverColor  md:m-0">
                                    <Link to="/Contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavigationBar;